import http from "../servicio/Servicio";
class LoginServicio {
  constructor() {
    this.componente = "login";
  }
  login = (data) => {
    return http.post("/" + this.componente , data);
  };
}

export default new LoginServicio();
