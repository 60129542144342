import React, { Component,useState } from 'react';
import ReactDom from 'react-dom'
import { DataTable } from 'primereact/datatable';
import { Fieldset } from 'primereact/fieldset';
import {Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';

import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import servicio from "./PlanillaServicio";

import token from "../login/Token"
class Planilla extends Component {
    
    constructor(props){
        super(props); 
        this.state={            
            planilla:[],
            visible:false,
            registro:{
                codigo_solicitud:"",
                comprador:"",
                telefono:"",
                monto:0
            }
            
        };
        this.refCodigoSolicitud = React.createRef();
        this.refComprador = React.createRef();
        this.refTelefono = React.createRef();
        this.refMonto = React.createRef();
        this.showSaveDialog = this.showSaveDialog.bind(this);
        this.save = this.save.bind(this);
        this.footer = (
            <div>
              <Button label="Guardar" icon="pi pi-check" onClick={this.save} />
            </div>
          );
    }
    save(){
        var t=token.get("token");
        this.setState({
            visible : false
            
          });
        var registro={
            user: token.get("user"),
            t: t,
            ser: this.refCodigoSolicitud.current.value,
            nombre: this.refComprador.current.value,
            tel: this.refTelefono.current.value,
            pre:this.refMonto.current.value
        }
        servicio.save(registro).then(()=>{
            servicio.get(t).then((response) => {
                this.setState({planilla: response.data});
            });
        });
    }
    showSaveDialog(){
        this.setState({
            visible : true,
            registro : {              
              codigo_solicitud: "",
              comprador: "",
              telefono: "",
              monto : ""
            }
          });
        //document.getElementById('registro-form').reset();
    }
    bodyNro(rowData,options){
        return (
            <React.Fragment>                
                {options.rowIndex+1}
            </React.Fragment>
        );
    }
    componentDidMount(){
        var t=token.get("token");
        servicio.get(t).
            then((response) => {
                this.setState({planilla: response.data});
            });
      }
    
    render() {
        return (
            <div>
                <div className="card">
                    <h2 className="text-center">Registro de Software</h2>
                    <br></br>
                    <br></br>
                    <Button label="Nuevo" icon="pi pi-check" onClick={this.showSaveDialog} />
                    <br></br>
                    <br></br>
                    <Fieldset legend="Planilla">                    
                        <DataTable value={this.state.planilla}  className="scroll">
                            <Column header="NRO" body={this.bodyNro}></Column>
                            <Column field="codigo_solicitud" header="CODIGO SOLICITUD"></Column>
                            <Column field="producto_id" header="PRODUCTO ID"></Column>
                            <Column field="codigo_activacion" header="CODIGO ACTIVACION"></Column>
                            <Column field="comprador" header="COMPRADOR" ></Column>
                            <Column field="telefono" header="TELEFONO" ></Column>
                            <Column field="precio" header="MONTO" ></Column>
                            <Column field="fecha" header="FECHA" ></Column>
                        </DataTable>
                    </Fieldset>
                    <br></br>                                        
                </div>   
                <Dialog header="Registro" visible={this.state.visible} style={{width: '400px'}} footer={this.footer} modal={true} onHide={() => this.setState({visible: false})}>
                    <form id="registro-form">
                        <br></br>
                        <span className="p-float-label">
                            <InputText ref={this.refCodigoSolicitud} style={{width : '100%'}} id="codigo_solicitud" />
                            <label htmlFor="codigo_solicitud">CODIGO SOLICITUD</label>
                        </span>
                        <br/>
                        <span className="p-float-label">
                            <InputText ref={this.refComprador} style={{width : '100%'}} id="comprador" />
                            <label htmlFor="comprador">COMPRADOR</label>
                        </span>
                        <br/>
                        <span className="p-float-label">
                            <InputText ref={this.refTelefono} style={{width : '100%'}} id="telefono" />
                            <label htmlFor="telefono">TELEFONO</label>
                        </span>
                        <br/>
                        <span className="p-float-label">
                            <InputText ref={this.refMonto} style={{width : '100%'}} id="monto" />
                            <label htmlFor="monto">MONTO</label>
                        </span>
                    </form>
                </Dialog>                     
            </div>
        );
    }
}
export default Planilla;