import React, { useState } from "react";
//import PropTypes from "prop-types";
import "../login/Login.css";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import servicio from "./LoginServicio";
import token from "./Token";
import md5 from 'js-md5'
export default function Login() {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    var item = {
      user: username,
      pas: md5(password)
    };
    
    servicio
      .login(item)
      .then((response) => {
        console.log(response);
        if (response.data.token) {
            console.log(response.data.token);
            token.set("token",response.data.token);
            token.set("user",username);
            window.location.reload();
        } else {
            token.clear();
            console.log(response.data.mensaje);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="form-demo">
      <div className="flex justify-content-center">
        <div className="card">
          <h5 className="text-center">Login</h5>
          <form onSubmit={handleSubmit} className="p-fluid">
            <div className="field">
              <span className="p-float-label">
                <InputText
                  id="name"
                  name="name"                  
                  onChange={(e) => setUserName(e.target.value)}
                  autoFocus
                />
                <label htmlFor="name">Usuario*</label>
              </span>
            </div>

            <div className="field">
              <span className="p-float-label">
                <Password
                  id="password"
                  name="password"                  
                  onChange={(e) => setPassword(e.target.value)}
                  toggleMask
                />
                <label htmlFor="password">Password*</label>
              </span>
            </div>
            <Button type="submit" label="Submit" className="p-mt-2" />
          </form>
        </div>
      </div>
    </div>
  );
}
/*
Login.propTypes = {
  setToken: PropTypes.func.isRequired
};
*/
