class Token {
  get = (key) => {
    const tokenString = sessionStorage.getItem(key);
    return tokenString;
  };
  set = (key,data) => {
    sessionStorage.setItem(key, data);    
  };
  clear=()=>{
      sessionStorage.clear();
  }
}

export default new Token();
