import "./styles.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import Login from "./componente/login/Login";
import Planilla from "./componente/planilla/Planilla";
import token from "./componente/login/Token"
export default function App() {
  var mensaje=null;
  if (token.get("token")) {
    mensaje= <Planilla />;
  }else{
    mensaje= <Login />;
  }
  return (
    <div className="App">
      {mensaje}
    </div>
  );
}
