import http from "../servicio/Servicio";
class PlanillaServicio {
  constructor() {
    this.componente = "get";
  }
  get = (token) => {
    return http.get("/" + this.componente +"?tok="+token);
  };
  save=(data)=>{
    return http.post("/serial",data);
  }
}

export default new PlanillaServicio();
